<template>
	<NuxtPicture
		v-if="src"
		class="c-nuxt-picture-ext"
		:class="`c-nuxt-picture-ext--${isLoaded ? 'is-loaded' : 'is-loading'}`"
		:style="computedStyle"
		:fit="fit"
		:src="src"
		:alt="alt"
		:sizes="sizes"
		:width="width"
		:height="height"
		:modifiers="{
			ratio: ratio,
			sourceWidth: width,
			sourceHeight: height,
			...modifiers,
		}"
		:img-attrs="computedImgAttrs"
		:quality="quality"
		:loading="loading"
		@load="onLoad"
	/>
</template>
<script>
// https://image.nuxtjs.org/components/nuxt-picture and https://image.nuxtjs.org/components/nuxt-img

export default {
	name: 'NuxtPictureExt',

	props: {
		alt: {
			type: String,
			default: '',
		},
		src: {
			type: String,
			required: true,
		},
		sizes: {
			type: String,
			default: null,
		},
		width: { type: [Number, String], required: false },
		height: { type: [Number, String], required: false },
		ratio: { type: [Number, String], required: false },
		fit: { type: String, default: 'crop' },
		loading: {
			type: String,
			default: 'lazy',
			validator: (value) => ['lazy', 'eager', 'auto'].includes(value),
		},

		imgAttrs: { type: Object, default: () => ({}) },
		quality: { type: [Number, String], default: 90 },
		modifiers: { type: Object, default: () => ({}) },
	},

	data() {
		return {
			isLoaded: false,
		};
	},

	computed: {
		computedStyle() {
			let style = null;

			if (this.ratio) {
				style = { aspectRatio: this.ratio };
			} else if (this.width && this.height) {
				style = { aspectRatio: `${this.width} / ${this.height}` };
			}

			return style;
		},

		computedImgAttrs() {
			const className = ['c-nuxt-picture-ext__img', this.imgAttrs.class];
			const style = [this.imgAttrs.style];

			if (this.fit && this.fit !== 'crop') {
				style.unshift({ objectFit: this.fit });
			}

			return {
				...this.imgAttrs,
				class: className.filter(Boolean),
				style: style.filter(Boolean),
			};
		},
	},

	methods: {
		onLoad(e) {
			this.$emit('load', e);
			this.isLoaded = true;
		},
	},
};
</script>
<style lang="postcss">
:where(.c-nuxt-picture-ext__img) {
	width: 100%;
	height: 100%;
}
</style>
